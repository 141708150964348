.pagination {
    justify-content: center;
    display: flex;
    padding-left: 0;
    list-style: none;
    margin: 0;
}

.page-item .page-link {
    position: relative;
    display: block;
    margin: 0 2px;
    height: 30px;
    min-width: 30px;
    line-height: 30px;
    border-radius: 5px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
}

.page-item a.page-link:hover {
    background-color: #002e5f;
}

.page-item.active .page-link {
    font-weight: 600;
    color: #000;
    background-color: #4cceac;
}

.page-item.disabled .page-link {
    color: #b8b8b8;
    pointer-events: none;
    cursor: auto;
}